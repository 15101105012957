<template>
  <div>
    <b-row>
      <!-- Profile Card -->
      <b-col
        lg="9"
        md="12"
        class="mb-1"
      >
        <b-card
          no-body
          class="p-2 h-100"
        >
          <b-row
            v-if="loading"
            class="text-center"
          >
            <b-col>
              <b-spinner label="Loading..." />
            </b-col>
          </b-row>

          <b-row v-if="organizer">
            <b-col
              md="7"
              sm="12"
            >
              <b-row class="flex-nowrap">
                <b-col
                  md="3"
                  lg="4"
                >
                  <!-- Avatar -->
                  <b-avatar
                    :src="organizer?.avatar_image"
                    :text="organizer?.name?.substring(0, 2)"
                    rounded
                    size="120px"
                    variant="light-primary"
                  />
                  <!--/ Avatar -->
                </b-col>

                <b-col
                  md="9"
                >
                  <b-row class="flex-column">
                    <b-col class="d-flex align-items-start">
                      <feather-icon
                        v-if="organizer?.is_verified"
                        size="25"
                        style="color: #27AE60"
                        class="mr-1"
                        icon="CheckCircleIcon"
                      />
                      <h3 class="mb-0 mr-1">
                        {{ organizer?.name }}
                      </h3>
                    </b-col>

                    <b-col>
                      {{ organizer?.email }}
                    </b-col>

                    <b-col class="d-flex align-items-center">
                      <span
                        v-if="organizer?.country"
                        class=""
                      >
                        <country-flag
                          :country="organizer?.country?.code?.toLowerCase()"
                          size="normal"
                        /></span>
                      <span class="ml-1">
                        {{ organizer?.country?.name }}
                      </span>
                    </b-col>
                  </b-row>

                  <!-- social links -->
                  <b-row>
                    <b-col class="mb-1 mr-1">
                      <social-links-list :links="organizer?.links" />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="flex-nowrap mt-1">
                <b-col
                  md="3"
                  lg="4"
                >
                  <b-badge
                    class="p-1 font-medium-2 w-100"
                    style="max-width: 120px"
                    variant="light-success"
                  >
                    {{ organizer?.balance || 0 }} €
                  </b-badge>
                </b-col>

                <b-col
                  md="9"
                >
                  <b-row class="flex-column">
                    <b-col
                      md="6"
                    >
                      <b-button
                        class="mb-1 font-small-1 w-100"
                        disabled="disabled"
                        size="sm"
                        variant="primary"
                      >
                        Send funds
                      </b-button>
                    </b-col>

                    <b-col
                      md="6"
                    >
                      <b-button
                        :to="{ name: 'apps-users-edit', params: { id: organizer?.id } }"
                        class="mb-1 font-small-1  w-100"
                        disabled="disabled"
                        size="sm"
                        variant="primary"
                      >
                        Withdraw funds
                      </b-button>
                    </b-col>
                  </b-row>

                </b-col>
              </b-row>
              <!-- /Wallet -->

            </b-col>
            <!-- Latest tournaments -->
            <b-col
              md="5"
              sm="12"
            >
              <h4>Our latest tournaments:</h4>
              <ul class="list-unstyled">
                <li
                  v-for="tournament in organizer?.tournaments?.filter(t => t.status_id === TournamentStatusType.PUBLISHED || t.status_id === TournamentStatusType.IN_PROGRESS)"
                  :key="tournament.id"
                >
                  <b-row class="flex-nowrap">
                    <b-col class="pr-0">
                      <feather-icon icon="CircleIcon" />
                    </b-col>
                    <b-col sm="7">
                      <b-link
                        :to="{
                          name: 'tournament-overview',
                          params: { id: tournament.id }
                        }"
                      >

                        <h5>{{ tournament.name }}</h5>

                      </b-link>
                    </b-col>
                    <b-col sm="4">
                      {{ tournamentStatus(tournament) }}
                    </b-col>
                  </b-row>
                </li>
              </ul>
            </b-col>
            <!-- /Latest tournaments -->

          </b-row>
        </b-card>
      </b-col>
      <!-- /Profile Card -->

      <!-- Team Members Card -->
      <b-col
        lg="3"
        md="12"
        class="mb-1"
      >
        <organizer-members-card :members="organizer?.members" />
      </b-col>
      <!-- /Team Members Card -->
    </b-row>

    <!-- search filters -->
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          class="p-2"
        >
          <b-form-checkbox-group
            v-model="filters"
            :options="filterOptions"
            switches
            @change="handleFilterChange"
          />
        </b-card>
      </b-col>
    </b-row>
    <!-- /search filters -->

    <!-- search results -->
    <b-row
      v-if="resultsLoading"
    >
      <b-col>
        <b-card
          no-body
          class="p-2"
        >
          <b-spinner label="Loading..." />
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="!resultsLoading && (!organizer?.tournaments || organizer?.tournaments.length === 0)">
      <b-col class="d-flex flex-column align-items-center">
        <feather-icon
          class="m-2"
          icon="FrownIcon"
          size="50"
        />
        There are no competitions
      </b-col>
    </b-row>

    <b-row v-if="!resultsLoading && tournaments && tournaments.length > 0">
      <b-col
        v-for="tournament in tournaments"
        :key="tournament?.id"
        md="6"
        lg="4"
        sm="12"
        class="mt-1"
      >
        <b-link
          :to="{
            name: 'tournament-info', params: { id: tournament.id, game: tournament.discipline?.slug }
          }"
        >
          <tournament-search-result-card :tournament="tournament" />
        </b-link>
      </b-col>
    </b-row>
    <!-- /search results -->
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BButton,
  BCard,
  BCol, BFormCheckboxGroup, BLink,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import SocialLinksList from '@/views/common/SocialLinksList.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import CountryFlag from 'vue-country-flag'
import TournamentStatusType from '@/constants/TournamentStatusType'
import { Timezones } from '@/constants/Timezones'
import OrganizerMembersCard from '@/views/organizer/OrganizerMembersCard.vue'
import TournamentSearchResultCard from '@/views/common/TournamentSearchResultCard.vue'
import { timeTillTournamentStart } from '@/views/tournament/tournament-utils'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'

export default {
  components: {
    TournamentSearchResultCard,
    OrganizerMembersCard,
    BLink,
    BButton,
    SocialLinksList,
    BAvatar,
    BBadge,
    BSpinner,
    BRow,
    BCol,
    BCard,
    CountryFlag,
    BFormCheckboxGroup,
  },
  mixins: [breadcrumbManager],
  data() {
    return {
      TournamentStatusType,
      Timezones,
      organizer: null,
      tournaments: null,
      filters: ['passed'],
      loading: false,
      resultsLoading: false,
      filterOptions: [
        {
          text: 'Entry fee',
          value: 'entry_fee',
        },
        {
          text: 'Include passed',
          value: 'passed',
        },
        {
          text: 'Private',
          value: 'private',
        },
      ],
    }
  },
  async mounted() {
    await this.fetchOrganizer()
  },
  methods: {
    async handleFilterChange() {
      this.tournaments = this.organizer?.tournaments.filter(tournament => {
        if (this.filters.includes('entry_fee') && this.filters.includes('passed')) {
          return tournament?.has_participation_fee === true && tournament.status_id > TournamentStatusType.DRAFT
        } if (this.filters.includes('entry_fee')) {
          return tournament?.has_participation_fee === true && (tournament.status_id > TournamentStatusType.DRAFT && tournament.status_id < TournamentStatusType.FINISHED)
        }

        if (this.filters.includes('passed')) {
          return tournament.status_id > TournamentStatusType.DRAFT
        }

        // default filter apply
        return (tournament.status_id > TournamentStatusType.DRAFT) && !tournament.has_participation_fee
      })
    },
    async fetchOrganizer() {
      this.loading = true

      const { data } = await this.$api.organizer.organizerById(
        this.$route.params.id,
      )

      this.loading = false

      this.organizer = data

      this.tournaments = this.organizer?.tournaments?.filter(tournament => (tournament.status_id > TournamentStatusType.DRAFT)) || []

      this.replaceBreadcrumb({
        find: 'organizerName',
        replace: { text: this.organizer?.name, loading: false },
      })
    },
    async fetchOrganizerTournaments() {
      this.resultsLoading = true

      const { data } = await this.$api.tournament.fetchTournaments(
        null,
        this.filters.includes('entry_fee'),
        this.filters.includes('passed'),
        this.filters.includes('private'),
        null,
        null,
      )

      this.tournaments = data

      this.resultsLoading = false
    },
    tournamentStatus(tournament) {
      if (tournament.status_id === TournamentStatusType.PUBLISHED) {
        return `Upcoming ${timeTillTournamentStart(tournament)}`
      }
      if (tournament.status_id === TournamentStatusType.IN_PROGRESS) {
        return 'LIVE'
      }
      if (tournament.status_id === TournamentStatusType.FINISHED || tournament?.status_id === TournamentStatusType.CLOSED) {
        return 'Finished'
      }

      return ''
    },
  },
}
</script>

<style lang="scss">
a h5 {
  color: #7367f0 !important;
}
</style>
